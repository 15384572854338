import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { CustomErrorHandler } from './shared/customerror.handler';
import { SharedModule } from './shared/shared.module';

import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HomeModule,
    SharedModule,
    AuthConfigModule,
  ],
  providers: [provideAnimations(), {provide: ErrorHandler, useClass: CustomErrorHandler} // override default error handler
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
