import { NgModule } from '@angular/core';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SharedModule } from '../shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';

@NgModule({
  imports:      [ SharedModule, HomeRoutingModule, ProgressbarModule.forRoot() ],
  declarations: [ HomeComponent],
  exports:      [ HomeComponent ]
})
export class HomeModule { }
