<h1 class="text-center">{{ 'home.welcome' | translate}}</h1>
<h3 class="text-center">{{ 'home.introduction' | translate}}</h3>
<div class = "row"  *hasRoles="['SERVICE_CENTER', 'FINANCIAL_USER']">
	<div class = "col-lg-4 col-md-6">
    	<a routerLink="/store-finder" routerLinkActive="active" class = "card home-card pull-center">
    		<img class="card-img-top" src="/assets/store-finder.png">
    		<h2 class= "store-finder card-block">{{'home.storeFinder' | translate}}</h2>
    	</a>
    </div>
    <div class = "col-lg-4 col-md-6">
    	<a routerLink="/product-finder" routerLinkActive="active" class = "card home-card pull-center">
    		<img class="card-img-top" src="/assets/product-finder.png">
    		<h2 class= "product-finder card-block">{{'home.productFinder' | translate}}</h2>
    	</a>
    </div>
    <div class = "col-lg-4">
    	<a routerLink="/datasync" routerLinkActive="active" class = "card home-card pull-center">
    		<img class="card-img-top" src="/assets/datasync.png">
    		<h2 class= "datasync card-block">{{'home.datasync' | translate}}</h2>
    	</a>
    </div>
</div>
<div class = "row"  *hasRoles="['DEFAULT']">
    <div class = "col-md-6">
        <a routerLink="/store-finder" routerLinkActive="active" class = "card home-card pull-center">
            <img class="card-img-top" src="/assets/store-finder.png">
            <h2 class= "store-finder card-block">{{'home.storeFinder' | translate}}</h2>
        </a>
    </div>
    <div class = "col-md-6">
        <a routerLink="/product-finder" routerLinkActive="active" class = "card home-card pull-center">
            <img class="card-img-top" src="/assets/product-finder.png">
            <h2 class= "product-finder card-block">{{'home.productFinder' | translate}}</h2>
        </a>
    </div>
</div>
