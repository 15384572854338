import { CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
    provideHttpClient,
    withInterceptors
} from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { CookieModule } from 'ngx-cookie';
import { MessageComponent } from './component/message/message.component';
import { SpinnerComponent } from './component/spinner.component';
import { authInterceptor, errorInterceptor } from './service/httpinterceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CookieModule.forRoot(),
    // Including the ReactiveFormsModule in our application
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FaIconComponent
  ]
  ,
  declarations: [
    MessageComponent,
    SpinnerComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    MessageComponent,
    SpinnerComponent,
  ],
  providers: [
    provideHttpClient(
      withInterceptors([authInterceptor, errorInterceptor],)
    ),
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
