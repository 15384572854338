import { Component, EventEmitter, Output } from '@angular/core';
import { isQuerying } from '../service';

@Component({
    selector: 'spinner',
    template: `
        <div *ngIf="isLoading" role="alert">
            <i class="fa fa-pulse fa-spinner" aria-hidden="true"></i>
        </div>
     `
})

export class SpinnerComponent {

  isLoading: Boolean = false;

  @Output() isLoadingChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor() {
    isQuerying.subscribe(
      isQuerying => {
        this.isLoading = isQuerying;
      }
    );
  }
}
