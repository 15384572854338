import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { HomeComponent } from './home.component';

@NgModule({
  imports: [RouterModule.forChild([
    { path: 'home', component: HomeComponent, canActivate: [AutoLoginPartialRoutesGuard]}
  ])],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
