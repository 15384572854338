import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        triggerAuthorizationResultEvent: true,
        postLoginRoute: '/home',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        authority: environment.oauthEndpoint,
        redirectUrl: `${window.location.origin}/authenticate`,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'datasync-public-client',
        scope: 'openid profile',
        responseType: 'code',
        silentRenew: false,
        secureRoutes: [
          `${environment.endpoint}/datasync-service/`,
          `${environment.endpoint}/product-service/`,
          `${environment.endpoint}/store-service/`,
          `${environment.endpoint}/bxp-service/`,
        ]
        // renewTimeBeforeTokenExpiresInSeconds: 10,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
