import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { setTheme } from 'ngx-bootstrap/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { GlobalLanguageEventService } from './core/service';
import { DatasyncService } from './core/service/datasync.service';
import { NotificationService } from './core/service/notification.service';
import { SuperExportService } from './core/service/superexport.service';

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public static KEY_LANG_STORAGE = 'MCDONALD_DIGITAL_APP_LANG';
  public static AVAILABLE_LANGS = ['fr', 'en', 'de', 'it'];

  outageFileURI: any;
  storeFileURI: any;
  environment = environment;

  public userData: any;

  public currentLang: string;
  private currentRoute: string;

  constructor(private translate: TranslateService,
              private datasyncService: DatasyncService,
              private globalLanguageEventService: GlobalLanguageEventService,
              private router: Router,
              private notificationService: NotificationService,
              private superExportService: SuperExportService,
              private oidcSecurityService: OidcSecurityService
  ) {
    setTheme('bs5');
    // We test if there's a lang set in navigator's LocalStorage for this app.
    let definedLang: string = localStorage.getItem(AppComponent.KEY_LANG_STORAGE) as string;

    if (!definedLang && translate.getBrowserLang()) {
      // If no lang are defined in LocalStorage then we'll try use the BrowserLang
      if (AppComponent.AVAILABLE_LANGS.indexOf(<string>translate.getBrowserLang()) > -1) {
        definedLang = translate.getBrowserLang() as string;
      } else {
        // If BrowserLang is not available in the app langs then we'll use english by default
        definedLang = 'en';
      }
    }

    translate.addLangs(AppComponent.AVAILABLE_LANGS);
    translate.setDefaultLang('en');
    translate.use(<string>definedLang);
    this.currentLang = definedLang;

    this.outageFileURI = this.datasyncService.getOutageFileURI();
    this.storeFileURI = this.datasyncService.getProductFileURI();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.notificationService.clear();
      }
    });
    this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      // Subscribe to any `NavigationEnd` events where the url has changed
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((x: any) => {
      ga('set', 'page', x.url);
      ga('send', 'pageview');
    });
  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe((userData) => {
      if (!userData.isAuthenticated) {
        this.oidcSecurityService.authorize();
      }
    });
  }

  public swapLang($event) {
    localStorage.setItem(AppComponent.KEY_LANG_STORAGE, this.currentLang);
    this.translate.use(this.currentLang);
    this.globalLanguageEventService.setLanguage(this.currentLang);
    window.location.reload();
  }

  export(url: any) {
    this.superExportService.getExport(url).subscribe((response) => {
      const blob = new Blob([response], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  logout() {
    this.oidcSecurityService
      .logoff()
      .subscribe((result) => console.log(result));
  }
}
