import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../core/service/notification.service';

@Injectable({providedIn: 'root'})
export class CustomErrorHandler implements ErrorHandler {

    constructor(@Inject(NotificationService) private notificationService: NotificationService,
        @Inject(TranslateService) private translate: TranslateService) {
    }

    handleError(error: any): void {
        console.error('Error handled ', error);
        setTimeout(() => this.notificationService.error(this.translate.instant('message.error')), 1);
    }

}
