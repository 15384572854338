import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AuthenticateComponent } from './authenticate/authenticate.component';

export const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {
    path: 'store-finder',
    loadChildren: () => import('./store-finder/store-finder.module').then((m => m.StoreFinderModule)),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'product-finder',
    loadChildren: () => import('./product-finder/product-finder.module').then((m => m.ProductFinderModule)),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'datasync',
    loadChildren: () => import('./datasync/datasync.module').then((m => m.DatasyncModule)),
    canActivate: [AutoLoginPartialRoutesGuard]
  }, {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then((m => m.UnauthorizedModule)),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m => m.HelpModule)),
    canActivate: [AutoLoginPartialRoutesGuard]
  },
  {
    path: 'authenticate', component: AuthenticateComponent
  },
  {path: '**', redirectTo: 'home'}
];

export const AppRoutingModule = RouterModule.forRoot(routes);
