import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SuperExportService {

  constructor(private http: HttpClient) {
  }

  getExport(url: string): Observable<any> {
    return this.http.get(url, {responseType: 'blob', });
  }
}
