<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="header-link navbar-brand" routerLink="/#" routerLinkActive="active">
      <img src="/assets/header_golden_arches.png" width="30" height="30" class="d-inline-block align-top" alt="">
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link header-link store-finder text-grey" routerLink="/store-finder"
             routerLinkActive="active">{{ 'header.storeFinder' | translate }}</a>&emsp;
        </li>
        <li class="nav-item">
          <a class="nav-link header-link product-finder text-grey" routerLink="/product-finder"
             routerLinkActive="active">{{ 'header.productFinder' | translate }}</a>&emsp;
        </li>
        <li class="nav-item">
          <a class="nav-link header-link datasync text-grey" routerLink="/datasync"
             *hasRoles="['SERVICE_CENTER', 'FINANCIAL_USER']" routerLinkActive="active">{{ 'header.datasync' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="container-fluid nav-end">
    <span
      class="nav-item userinfo text-grey hidden-sm-down welcome">{{ 'header.welcome' | translate }} {{ userData?.given_name }} {{ userData?.family_name }}</span>&emsp;
    <span><a class="nav-item text-grey icon-header" (click)="logout()"><i
      class="fa fa-sign-out"></i></a></span>&emsp;
    <div class="btn-group super-export" dropdown *hasRoles="['SERVICE_CENTER', 'FINANCIAL_USER']">
      <button class="btn btn-primary dropdown-toggle" dropdownToggle id="dropdownSuperExportButton" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="true">
        {{ 'header.export.title' | translate }}
      </button>
      <ul class="dropdown-menu super-export-menu" *dropdownMenu>
        <li *hasRoles="['FINANCIAL_USER']" role="menuitem"><div class="dropdown-item" (click)="export(storeFileURI)">{{ 'header.export.exportProduct' | translate }}</div></li>
        <li role="menuitem"><div class="dropdown-item" (click)="export(outageFileURI)">{{ 'header.export.exportOutage' | translate }}</div></li>
      </ul>
    </div>&emsp;
    <span class="lang-switch">
      <select [(ngModel)]="currentLang" (change)="swapLang($event)" class="nav-item form-control">
        <option value="fr">FR</option>
        <option value="en">EN</option>
        <option value="de">DE</option>
        <option value="it">IT</option>
      </select>
    </span>&emsp;
    <span><a class = "text-grey icon-header" routerLink="/help" routerLinkActive="active"><i class="fa fa-question-circle"></i></a></span>
    <i class="fa fa-question-circle"></i>

  </div>
</nav>

<spinner></spinner>

<main>
  <message></message>
  <router-outlet></router-outlet>
</main>

<footer>

</footer>
